<template>
  <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
    <div class="row">
      <div class="col-12">
        <div class="row" v-if="isLoad">
          <div class="col-12 text-center">
            <div class="my-3">
              <div class="spinner-grow text-warning mx-3" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <div class="spinner-grow text-warning mx-3" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <div class="spinner-grow text-warning mx-3" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <div class="spinner-grow text-warning mx-3" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <div class="spinner-grow text-warning mx-3" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <div class="spinner-grow text-warning mx-3" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-else>
          <form @submit.prevent="postPermission()" class="col-12">
            <div class="row">
              <div class="col-12">
                <table v-for="(item, i) in permission" :key="i" class="table table-hover table-bordered">
                  <thead>
                    <tr>
                      <th colspan="3" class="border-0 bg-current text-white p-3">{{item.name}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(menu, j) in item.menu" :key="j">
                      <td class="text-center" width="10%"><input type="checkbox" v-model="menu.permission"></td>
                      <td class="text-center" width="10%"><i :class="menu.icon"></i></td>
                      <td>{{menu.name}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-12 text-right">
                <button type="submit" class="btn btn-current">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
export default {
  data(){
    return{
      isLoad: true,
      idRole: this.$route.params.idRole,
      permission: []
    }
  },
  created() {
    this.getPermission()
  },
  methods: {
    async getPermission() {
      await axios.get(`${process.env.VUE_APP_URL_API}/admin/permission?role_id=${this.idRole}`, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`
        }
      }).then(res => {
        this.permission = res.data
        this.isLoad = false
      })
    },
    async postPermission(){
      var permission = [];
      this.permission.map((item) => {
        item.menu.map((menu) => {
          var per = {
            id: menu.id,
            permission: menu.permission
          }
          permission.push(per)
        })
      })
      var data = {
        role_id: this.idRole,
        permission: permission
      }
      await axios.post(`${process.env.VUE_APP_URL_API}/admin/permission`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`
        }
      }).then(res => {
        this.$swal({
          toast: true,
          title: 'Permission!',
          text: res.data.message,
          icon: 'success',
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        })
        this.$router.push({ name: 'PrivillageAdmin' })
      }).catch((err) => console.log(err))
    }
  }
}
</script>